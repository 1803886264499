import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
const apiUrl = "/admin-api";

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient) { }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(error.error.message);
  };

  private extractData(res: Response) {
    let body = res;
    return body || {};
  }

  getTableRecords(data): Observable<any> {
    return this.http.post(apiUrl + "/fetch", data, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }

  addTableRecord(data): Observable<any> {
    return this.http.post(apiUrl + "/create", data, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }

  modifyTableRecord(data): Observable<any> {
    return this.http.post(apiUrl + "/modify", data, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }

  deleteTableRecord(table: string, id: string): Observable<any> {
    return this.http.delete(apiUrl + "/delete/" + table + "/" + id, httpOptions).pipe(
      map(this.extractData),
      catchError(this.handleError));
  }

  // authorize(data): Observable<any> {
  //   return this.http.post(apiUrl + "/login", data, httpOptions).pipe(
  //     map(this.extractData),
  //     catchError(this.handleError));
  // }

  // postBook(data): Observable<any> {
  //   return this.http.post(apiUrl, data, httpOptions)
  //     .pipe(
  //       catchError(this.handleError)
  //     );
  // }

  // updateBook(id: string, data): Observable<any> {
  //   const url = `${apiUrl}/${id}`;
  //   return this.http.put(url, data, httpOptions)
  //     .pipe(
  //       catchError(this.handleError)
  //     );
  // }

  // deleteBook(id: string): Observable<{}> {
  //   const url = `${apiUrl}/${id}`;
  //   return this.http.delete(url, httpOptions)
  //     .pipe(
  //       catchError(this.handleError)
  //     );
  // }
}
