import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
import { AuthComponent } from './components/auth/auth.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LogoutComponent } from './components/logout/logout.component';
import { MainComponent } from './components/main/main.component';
import { ControlMessagesComponent } from './components/ui/control-messages/control-messages.component';
import { NavbarComponent } from './components/ui/navbar/navbar.component';
import { AuthService } from './services/auth/auth.service';
import { ValidationService } from './services/ui/validation.service';

const appRoutes: Routes = [
  {
    path: 'auth',
    component: AuthComponent,
    data: { title: 'Login' }
  },
  {
    path: 'home',
    component: DashboardComponent,
    data: { title: 'Home' }
  },
  {
    path: 'index',
    component: MainComponent,
    data: { title: 'Pulse Robotics' }
  },
  {
    path: 'logout',
    component: LogoutComponent,
    data: { title: 'Pulse Robotics' }
  },
  {
    path: '',
    redirectTo: '/index',
    pathMatch: 'full'
  }
];


@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    DashboardComponent,
    NavbarComponent,
    MainComponent,
    LogoutComponent,
    ControlMessagesComponent
  ],
  imports: [
    ToastrModule.forRoot(),
    RouterModule.forRoot(appRoutes, { useHash: true }),
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule
  ],
  providers: [
    AuthService,
    ValidationService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
