import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AdminService } from '../../services/admin/admin.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  recs: any;
  showRPD: boolean;
  showAddRPDForm: boolean;
  rpd: any; // to add new rpd
  fetchSize: any;
  tableMaxHeight: any;

  constructor(private adminService: AdminService, private toastr: ToastrService) { }

  ngOnInit() {
    this.rpd = { scheme_code: '', scheme_name: '' };
    this.recs = {};
    this.fetchSize = 50;
    this.tableMaxHeight = (window.innerHeight - 150);
    this.fetchRecs('roi_portfolio_defaults', 0, 10);
  }

  addRPD() {
    this.rpd = {};
  }

  modifyRPDModal(r) {
    this.rpd = r;
    const rp = r.roi_range_risktype.split('_');
    this.rpd.min_month = rp[0];
    this.rpd.max_month = rp[1];
    this.rpd.risk_type = rp[2];
  }

  addRec(t) {
    const user = JSON.parse(localStorage.getItem('user'));

    const rec = JSON.parse(JSON.stringify(this.rpd));
    rec.table = 'roi_portfolio_defaults';
    rec.roi_range_risktype = rec.min_month + '_' + rec.max_month + '_' + rec.risk_type;
    rec.partner_id = user.id;

    this.adminService.addTableRecord(rec).subscribe(res => {
      this.toastr.success('Record added successfully!', 'Status', {
        timeOut: 3000,
      });

      this.fetchRecs('roi_portfolio_defaults', 0, 10);
    }, (err) => {
      this.toastr.error(err, 'Error:', {
        timeOut: 3000,
      });
    });
  }

  modifyRec(t) {
    const rec = JSON.parse(JSON.stringify(this.rpd));
    rec.table = 'roi_portfolio_defaults';
    rec.roi_range_risktype = rec.min_month + '_' + rec.max_month + '_' + rec.risk_type;

    this.adminService.modifyTableRecord(rec).subscribe(res => {
      this.toastr.success('Record modified successfully!', 'Status', {
        timeOut: 3000,
      });
      document.getElementById('close-rpd-modal').click();
      this.fetchRecs('roi_portfolio_defaults', 0, 10);
    }, (err) => {
      document.getElementById('close-rpd-modal').click();
      this.toastr.error(err, 'Error:', {
        timeOut: 3000,
      });
    });
  }

  deleteRPD() {
    const rec = JSON.parse(JSON.stringify(this.rpd));
    this.adminService.deleteTableRecord('roi_portfolio_defaults', rec.id).subscribe(res => {
      this.toastr.success('Record deleted successfully!', 'Status', {
        timeOut: 3000,
      });
      this.fetchRecs('roi_portfolio_defaults', 0, 10);
      document.getElementById('close-confirm-modal').click();
    }, (err) => {
      this.toastr.error(err, 'Error:', {
        timeOut: 3000,
      });
      document.getElementById('close-confirm-modal').click();
    });
  }

  fetchRecs(tableName: string, from: number, to: number) {
    const user = JSON.parse(localStorage.getItem('user'));
    const data = {
      table: tableName,
      from: from,
      size: this.fetchSize,
      partner_id: user.id
    };

    this.adminService.getTableRecords(data).subscribe(res => {
      const data = res.data;
      this.recs[tableName] = { fetchInProgress: false, total: data[1][0].count, from: 0, to: this.fetchSize, recs: data[0] };
      console.log(this.recs['roi_portfolio_defaults']['recs']);
    }, (err) => {
      this.toastr.error(err, 'Error:', {
        timeOut: 3000,
      });
    });
  }

  onGridScroll(tableName: string) {
    if (!this.recs[tableName].fetchInProgress && this.recs[tableName]['total'] >= this.recs[tableName]['from']) {
      console.log('Refresh in progress ...');
      this.recs[tableName].fetchInProgress = true;
      const user = JSON.parse(localStorage.getItem('user'));
      const from = (this.recs[tableName]['from'] + this.fetchSize);
      const data = {
        table: tableName,
        from: from,
        size: this.fetchSize,
        partner_id: user.id
      };

      console.log('Fetching from : ' + from + ' to ' + (from + this.fetchSize));

      this.adminService.getTableRecords(data).subscribe(res => {
        const data = res.data;
        this.recs[tableName].from = from;
        const recsNow = [...this.recs[tableName].recs];
        console.log('Before ' + recsNow.length);
        this.recs[tableName].recs = recsNow.concat(data[0]);
        console.log('After ' + this.recs[tableName].recs.length);
        this.recs[tableName].fetchInProgress = false;
      }, (err) => {
        this.recs[tableName].fetchInProgress = false;
        this.toastr.error(err, 'Error:', {
          timeOut: 3000,
        });
      });
    }
  }

}
