import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/auth/auth.service';
import { ValidationService } from '../../services/ui/validation.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})

export class AuthComponent implements OnInit {

  authForm: FormGroup;
  email: string = '';
  password: string = '';

  constructor(private router: Router, private authService: AuthService, private toastr: ToastrService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.authForm = this.formBuilder.group({
      'email': ['', [Validators.required, ValidationService.emailValidator]],
      'password': ['', Validators.required]
    });

    if (this.isAuthenticated()) {
      this.router.navigate(['/home']);
    }
  }

  authenticate(form: NgForm) {
    if (this.authForm.dirty && this.authForm.valid) {
      if (this.isAuthenticated()) {
        this.router.navigate(['/home']);
      } else {
        this.authService.authorize(form)
          .subscribe(res => {
            localStorage.setItem('user', JSON.stringify(res.data));
            localStorage.setItem('logged-in', "1");
            this.router.navigate(['/home']);
          }, (err) => {
            this.toastr.error(err, 'Error:', {
              timeOut: 3000,
            });
          });
      }
    } else {
      this.toastr.error('Invalid credentials ', 'Validation Errors:', {
        timeOut: 3000,
      });
    }
  }

  isAuthenticated() {
    return localStorage.getItem('logged-in') != null;
  }

  logout() {
    localStorage.clear();
  }

}

interface login {
  email: string,
  password: string
}
